import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
import { Menu } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "menu"
    }}>{`Menu`}</h1>
    <p>{`A classe menu utiliza a biblioteca popper para manipular o posicionamento dos objetos, para mais detalhes consulte a `}<a parentName="p" {...{
        "href": "https://popper.js.org/docs/v2/"
      }}>{`documentação`}</a>{`.`}</p>
    <br />
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface IMenuContent {
  label: string | { first: string; second: string }; // texto do botão
  id: string | number; // id do elemento - será passado como argumento para o evento onSlected
  selected?: boolean; // se o botão deve iniciar selecionado - default = false
  disabled?: boolean; // se true o botão não recebe interações do usuário
}

// Propriedades do construtor
export interface MenuProps {
  ref: HTMLElement; // referência do objeto que abre o menu, geralmente um botão
  content: IMenuContent[]; // conjunto de botões
  classes: string[]; // classes que serão passadas para o container do menu
  popperOptions?: Partial<Options>; // propriedades passadas para o construtor do popper
  arrow?: boolean; // indica se a ponta marcadora está visível - default = true
  color?: 'primary' | 'secondary'; // indica o estilo de cor do list-item - default = 'primary'
}

// construtor
export interface IMenuConstructor {
  new (props?: MenuProps): IMenu;
}

export interface IMenu {
  open: boolean; // indica se o menu está aberto ou fechado, se passar algum valor, o menu abre/true ou fecha/false - default = false
  selected: number | string; // seleciona ou retorna um item pelo id de forma manual
  readonly destroy: () => void; // método público, remove todos os elementos da página

  onClose?: () => void; // disparado quando o menu tem intensão de fechar
  onSelected?: (id: string | number) => void; // disparado quando clica em um elemento do menu
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Nota: o menu não fecha de forma automatizada, apenas indica que tem intenção de fechar, através do evento onClose. Ou seja,
deve-se implementar essa função setando a propriedade `}<strong parentName="p">{`open`}</strong>{` para false. Esse evento é disparado quando clica em algum elemento do menu ou
quando aberto clica na área fora do menu. Consulte o exemplo a seguir:`}</p>
    </blockquote>
    <Playground __position={1} __code={'() => {\n  const [menu, setMenu] = useState(null)\n  const [selected, setSelected] = useState(\'pt\')\n  //\n  const buttonRef = useRef()\n  //\n  useEffect(() => {\n    if (buttonRef.current && !menu) {\n      setMenu(\n        new Menu({\n          ref: buttonRef.current,\n          content: [\n            { label: \'inglês\', id: \'en\' },\n            { label: \'português\', selected: true, id: \'pt\' },\n            { label: \'espanhol\', id: \'es\' },\n            {\n              label: { first: \'Andy Harris\', second: \'and@gmail.com\' },\n              id: \'name\',\n              disabled: true,\n            },\n          ],\n          classes: [\'tw-w-52\'],\n        }),\n      )\n    }\n    //\n    if (menu) {\n      menu.onClose = handleClose\n      menu.onSelected = handleSelected\n    }\n    //\n    return () => {\n      if (menu) menu.destroy()\n    }\n  }, [buttonRef.current, menu])\n  //\n  const toggleMenu = () => {\n    if (menu) {\n      menu.open = !menu.open\n    }\n  }\n  //\n  const handleSelected = id => {\n    setSelected(id)\n  }\n  //\n  const handleClose = () => {\n    if (menu) menu.open = false\n  }\n  return (\n    <div className=\"tw-flex\">\n      <button\n        ref={buttonRef}\n        className=\"tw-btn tw-btn-primary\"\n        onClick={toggleMenu}\n      >\n        abrir menu\n      </button>\n      <div className=\"tw-ml-7\">\n        <p className=\"tw-body2\">\n          item selecionado: <span className=\"tw-text-alert\">{selected}</span>\n        </p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      Menu,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [menu, setMenu] = useState(null);
        const [selected, setSelected] = useState('pt'); //

        const buttonRef = useRef(); //

        useEffect(() => {
          if (buttonRef.current && !menu) {
            setMenu(new Menu({
              ref: buttonRef.current,
              content: [{
                label: 'inglês',
                id: 'en'
              }, {
                label: 'português',
                selected: true,
                id: 'pt'
              }, {
                label: 'espanhol',
                id: 'es'
              }, {
                label: {
                  first: 'Andy Harris',
                  second: 'and@gmail.com'
                },
                id: 'name',
                disabled: true
              }],
              classes: ['tw-w-52']
            }));
          } //


          if (menu) {
            menu.onClose = handleClose;
            menu.onSelected = handleSelected;
          } //


          return () => {
            if (menu) menu.destroy();
          };
        }, [buttonRef.current, menu]); //

        const toggleMenu = () => {
          if (menu) {
            menu.open = !menu.open;
          }
        }; //


        const handleSelected = id => {
          setSelected(id);
        }; //


        const handleClose = () => {
          if (menu) menu.open = false;
        };

        return <div className="tw-flex">
        <button ref={buttonRef} className="tw-btn tw-btn-primary" onClick={toggleMenu}>
          abrir menu
        </button>
        <div className="tw-ml-7">
          <p className="tw-body2">
            item selecionado: <span className="tw-text-alert">{selected}</span>
          </p>
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      